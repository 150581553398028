<template>
  <div>
    <p class="text-xl text-grey-light font-medium font-headers mb-3.5">
      Admins / <span class="font-bold text-grey-dark-3">{{ fullName }}</span>
    </p>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <div class="flex justify-between items-center pb-2.5">
        <p class="text-grey-light font-bold font-headers text-mdl">
          Admins Details
        </p>
        <div class="flex flex-row flex-grow justify-end">
          <Button
            type="secondary"
            size="medium"
            text="Re-Send Platform Invitation"
            @click.native="resendPlatformInvitation"
            class="mr-2"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            @click.native="edit"
            :disabled="isDisabledButton || !isChanged"
          />
        </div>
      </div>

      <div class="pl-2">
        <div class="flex justify-between space-x-2 w-2/5 mb-3">
          <InputField
            label="First Name"
            type="text"
            class="flex-grow"
            :onChange="(value) => (firstName = value)"
            :defaultValue="firstName"
            :isTextOk="$v.firstName.$invalid ? false : null"
            errorText="First name is required"
          />
          <InputField
            label="Last Name"
            type="text"
            class="flex-grow"
            :onChange="(value) => (lastName = value)"
            :defaultValue="lastName"
            :isTextOk="$v.lastName.$invalid ? false : null"
            errorText="Last name is required"
          />
        </div>
        <InputField
          label="Email"
          class="w-2/5"
          type="text"
          :onChange="(value) => (email = value)"
          :defaultValue="email"
          :isTextOk="$v.email.$invalid ? false : null"
          :errorText="isEmailInvalid"
        />
      </div>
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow mt-2">
      <p class="text-grey-light font-bold font-headers text-mdl">Status</p>
      <div class="pt-1.5 px-2">
        <p class="text-grey-dark-1 text-mdh">
          A user can be deactivated and reactivated at any time. Deactivating a
          user does not remove their data for past assessments.
        </p>
        <div class="flex mt-2 justify-between items-center">
          <p
            class="text-md"
            :class="selectedUser.active ? 'text-green' : 'text-red'"
          >
            {{ selectedUser.active ? 'Active' : 'Inactive' }}
          </p>
          <button
            class="text-md font-semibold"
            :class="
              selectedUser.active
                ? 'text-red'
                : 'text-sm-2 font-medium text-blue-dark-1 px-2 py-1 bg-blue-light-1 rounded'
            "
            @click="deactivate"
          >
            {{
              selectedUser.active ? 'Deactivate Account' : 'Activate Account'
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/Button/Button.vue';
import InputField from '../../../components/InputField/InputField.vue';
import { mapActions, mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { InputField, Button },
  name: 'ManagerUser',
  data: () => ({ initialData: {} }),
  mounted() {
    this.initialData = JSON.parse(JSON.stringify(this.selectedUser));
  },
  computed: {
    ...mapState({ selectedUser: (state) => state.companies.selectedUser }),
    isChanged() {
      let check1 = this.firstName !== this.initialData.firstName;
      let check2 = this.lastName !== this.initialData.lastName;
      let check3 = this.email !== this.initialData.email;
      return check3 || check2 || check1;
    },
    fullName() {
      return this.firstName + ' ' + this.lastName;
    },
    firstName: {
      get() {
        return this.selectedUser.firstName;
      },
      set(value) {
        this.selectedUser.firstName = value;
      },
    },
    lastName: {
      get() {
        return this.selectedUser.lastName;
      },
      set(value) {
        this.selectedUser.lastName = value;
      },
    },
    email: {
      get() {
        return this.selectedUser.email;
      },
      set(value) {
        this.selectedUser.email = value;
      },
    },
    isEmailInvalid() {
      if (!this.$v.email.email) {
        return 'Email is invalid';
      } else if (!this.$v.email.required) {
        return 'Email is required';
      }
    },
    isDisabledButton() {
      return this.$v.$invalid;
    },
  },
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
  },
  methods: {
    ...mapActions({
      updateSeller: 'companies/updateSeller',
      openModal: 'modals/openModal',
      changeUserStatus: 'companies/changeUserStatus',
      resendInvitation: 'companies/resendInvitation',
    }),
    async resendPlatformInvitation() {
      await this.resendInvitation({ id: this.selectedUser.id });
    },
    async edit() {
      await this.updateSeller({
        id: this.selectedUser.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      });
    },
    async deactivate() {
      if (this.selectedUser.active)
        this.openModal({
          modal: 'deactivateAccountModal',
          props: { userId: this.selectedUser.id },
        });
      else {
        await this.changeUserStatus({
          userId: this.selectedUser.id,
          isActive: true,
        });
      }
    },
  },
};
</script>
